
import check from "../store/elementChecker";
export default {
  name: "default",
  scrollToTop: false,
  head() {
    if (!this.$store.getters.store._id) return;
    let preview = "";
    if (this.$cookies.get("preview")) {
      let pr = decodeURIComponent(this.$cookies.get("preview") || "").split(
        ":::"
      );
      if (pr[1] == this.$store.getters.store._id) {
        preview = `&preview=${pr[0]}`;
      }
    }
    let route = {};
    route.name = this.$route.name;
    route.path = this.$route.path;
    if (
      this.$route.name == "catalog-full" &&
      this.$route.path.includes("/product/")
    ) {
      route.name = "item-id";
    }
    let cssInject = check(
      this.$store.getters.store._id,
      route,
      this,
      preview,
      ((this.$store.getters.store || {}).build_no || {}).timestamp,
      (this.$store.getters.store.build || {}).build_no,
      (this.$store.getters.store.eshop || {}).activeHijack
    );

    let canonical = `https://${this.$store.getters.host}${this.$route.path
      .split("/")
      .filter((s) => !s.includes("_or_"))
      .join("/")}`;

    let head = {
      [route.name == "index" ? "title" : "nada"]: this.$hyperSeo.title(),
      __dangerouslyDisableSanitizersByTagID: {
        description: ["script", "innerHTML"],
      },
      meta: [
        ...this.$hyperSeo.meta(),
        ...this.$hyperSeo.og(),
        {
          name: "app-version",
          content: this.$store.getters.store.cacheVersion,
        },
      ],
      link: [
        ...cssInject,
        {
          hid: "shortcut-icon",
          rel: "shortcut icon",
          type: (this.$store.getters.store.eshop.favicon || "").includes(".ico")
            ? "image/x-icon"
            : "image/png",
          href: this.$store.getters.store.eshop.favicon || "",
        },
        {
          hid: "apple-touch-icon",
          rel: "apple-touch-icon",
          type: (this.$store.getters.store.eshop.favicon || "").includes(".ico")
            ? "image/x-icon"
            : "image/png",
          href: this.$store.getters.store.eshop.favicon || "",
        },
        {
          hid: "icon",
          rel: "icon",
          type: (this.$store.getters.store.eshop.favicon || "").includes(".ico")
            ? "image/x-icon"
            : "image/png",
          href: this.$store.getters.store.eshop.favicon || "",
        },
        { rel: "manifest", href: "/manifest.json", hid: "manifest" },
        {
          hid: "canonical",
          rel: "canonical",
          href: canonical,
        },
      ],
      script: [
        {
          hid: "ldOrganization",
          type: "application/ld+json",
          json: this.$hyperSeo.schemas(
            "organization",
            this.$store.getters.item
          ),
        },
      ],
    };
    let injections = (
      (this.$store.getters.store.eshop || {}).injections || []
    ).filter(
      (i) =>
        i.ssr &&
        (i.url == "*"
          ? true
          : i.url == "/"
          ? this.$route.fullPath == "/"
          : this.$route.fullPath.includes(i.url))
    );
    injections.forEach((inj) => {
      if ((inj.multiple || []).length) {
        inj.multiple.forEach((i) => {
          if (!i.tag) {
            return;
          }
          let addition = {
            hid: i.attrName || i.content,
            name: i.attrName,
            content: i.attrContent,
            link: i.attrHref,
            src: i.attrSrc,
            innerHTML: i.innerHtml,
          };
          Object.keys(addition).forEach(
            (key) => addition[key] === undefined && delete addition[key]
          );
          // if (head[i.tag]) {
          //   head[i.tag].push(addition);
          // } else {
          //   head[i.tag] = [addition];
          // }
        });
      }
    });
    return head;
  },

  data() {
    return {
      title: "",
      favicon: "",
      storeSetup: false,
      header: "",
      navBar: "",
      siteMenu: "",
      footer: "",
      index: "",
      type: "",
      storeReady: false,
      loading: false,
      test: false,
      openNewsletter: false,
      newsLetterType: "subscribe",
      media: [],
      cartFocus: false,
    };
  },
  watch: {
    // "$store.getters.user": {
    //   handler(v) {
    //     if (v && !v.orders) {
    //       this.getOrders();
    //     }
    //   },
    // },
    "$store.getters.store.eshop.ready": function (v) {
      if (v == "no") {
        this.$store.commit("token", null);
        this.$cookies.remove("token");
      }
    },
    "$nuxt.$route.hash": {
      handler(v) {
        this.$nuxt.$emit("closeMenu");
        this.handleInjections();
        if (process.browser && v) {
          let elems = [...document.querySelectorAll(`a:not(a[href*="${v}"])`)];
          let elem = (elems.filter((a) => {
            return a.innerHTML.includes(
              decodeURIComponent(v).replace(/\#/, "")
            );
          }) || [])[0];
          if (elem) {
            let y = elem.getBoundingClientRect().top + window.scrollY;
            setTimeout(() => {
              window.scroll({
                top: y - 250,
                behavior: "smooth",
              });
            }, 10);
          }
          setTimeout(() => {
            try {
              this.$hyperUtility.scrollTo(location.href.split("#")[1]);
            } catch (error) {}
          }, 700);
        }
      },
      immediate: true,
      deep: true,
    },
    storeSetup: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
    storeReady: function (v) {
      if (!v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
    "$route.fullPath": {
      deep: true,
      handler(v) {
        this.$store.commit("url", decodeURIComponent(this.$route.fullPath));
        if (process.browser && this.$store.getters.filters.q) {
          this.$store.commit("history", {
            type: "search",
            search: this.$store.getters.filters.q,
          });
        }
        if (process.browser) {
          this.checkForceReload();
          setTimeout((_) => {
            this.$nuxt.$emit("informant:pageView");
          }, 400);
        }
        if (!this.$route?.name?.includes("catalog")) {
          this.$store.commit("isBackButton", false);
        }
        this.$nuxt.$emit("closeMenu");
      },
    },
    $route: function (v) {
      if (process.browser) {
        window.scrollTo(0, 0);
        this.$nuxt.$emit("closeMenu");
        //COUNTDOWN TIMERS
        if (this.$store.getters.timers) {
          Object.keys(this.$store.getters.timers || {}).forEach((t) => {
            if (!this.$store.getters.timers[t].output) {
              setInterval(() => {
                this.$store.commit(
                  "timers",
                  this.createCountdownTimer(this.$store.getters.timers[t])
                );
              }, 300);
            }
          });
        }
        document
          .querySelectorAll('[id*="shopster_injection_"]')
          .forEach((e) => {
            e.remove();
          });
      }
      if (v.name != "index") {
        this.$store.commit("expanded", false);
      }
    },
  },
  created() {
    this.$axios.interceptors.request.use(
      (config) => {
        if (!config.headers.Exclude) {
          this.loading = true;
        }
        return config;
      },
      (error) => {
        this.loading = true;
        if (process.browser) {
          $nuxt.$loading.finish();
        }
        return Promise.reject(error);
      }
    );
    this.$axios.interceptors.response.use(
      (config) => {
        this.loading = false;
        if (process.browser) {
          $nuxt.$loading.finish();
        }
        this.$nuxt.$loading.loading = false;

        return config;
      },
      (error) => {
        this.loading = false;
        if (process.browser) {
          $nuxt.$loading.finish();
        }
        return Promise.reject(error);
      }
    );
    this.loading = false;
    if (this.$nuxt.$loading) {
      this.$nuxt.$loading.loading = false;
    }
    if (process.browser) {
      if (window.matchMedia("(pointer: coarse)").matches) {
        this.$store.commit("isTouch", true);
      }
    }
  },
  beforeCreate() {},
  async beforeMount() {
    let self = this;
    if (process.browser) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.addEventListener("resize", function () {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    }

    // this.title = 'Loading...';
    if (!self.$store.getters.store._id) {
      self.storeSetup = true;
      if (self.$route.name != "item-id" && self.$route.name != "cart-step") {
        self.title =
          self.$store.getters.store.eshop.pageTitle ||
          self.$store.getters.store.eshop.name;
      }
    }
    if (self.$store.getters.store.logo != "") {
      if (!location.href.includes(localStorage.getItem("url"))) {
        this.clearStorage();
      }
      if (process.browser) {
        localStorage.setItem("url", `${location.protocol}//${location.host}`);
        self.$store.subscribe((mutation, state) => {
          localStorage.setItem(
            "state",
            JSON.stringify({ cart: state.cart, history: state.history })
          );
        });
        let history = localStorage.getItem("history");
        if (history) {
          try {
            self.$store.commit("history", JSON.parse(history));
          } catch (error) {}
        }
      }
    }
  },
  async mounted() {
    if (process.browser) {
      if (!(this.$route.name || "").includes("success")) {
        this.$store.commit("afterCart", {});
      }
      this.checkForceReload();
      this.$hyperLayout.initFeatures();
      console.info(
        "%c add .boundaries (class) to <body> to test outlines and overflows! ",
        "background: #ff6914; color: #fff;font-size:14px"
      );
      console.info(
        "%c for errors contact help@shopster.gr ",
        "background: #f54; color: #fff;font-size:16px"
      );
      let self = this;
      window.addEventListener("popstate", async function (e) {
        if (
          self.$route.fullPath.includes("product") ||
          self.$route.fullPath.includes("item")
        ) {
          self.$store.commit("isBackButton", true);
        }
      });
      //get Updated Cart
      if (!window.onfocus) {
        window.onfocus = async function () {
          if (
            self.$route.name == "cart-step" &&
            ((self.$route.params || {}).step || self.$store.getters.cart.step)
          ) {
            return;
          }

          self.$hyperCart.saveCart(await self.$hyperCart.getCart(false));
          self.$nuxt.$emit("updateCart", true);
        };
      }
      self.isMobileCalc();
      window.addEventListener("resize", function (e) {
        self.isMobileCalc();
      });
      window.addEventListener("scroll", function (e) {
        self.$hyperLayout.scrollDirection(window, e);
      });
      try {
        this.$store.commit(
          "notifications",
          Notification.permission == "granted"
        );
      } catch (error) {}
      try {
        localStorage.setItem("source", location.search);
        if (location.href.includes("#")) {
          setTimeout(() => {
            try {
              this.$hyperUtility.scrollTo(location.href.split("#")[1]);
            } catch (error) {}
          }, 700);
        }
      } catch (error) {}
      if (this.$store.getters.timers) {
        Object.keys(this.$store.getters.timers || {}).forEach((t) => {
          if (!this.$store.getters.timers[t].output) {
            setInterval(() => {
              this.$store.commit(
                "timers",
                this.createCountdownTimer(this.$store.getters.timers[t])
              );
            }, 300);
          }
        });
      }
      this.$nuxt.$emit("clientLoaded");
      this.$store.commit("clientLoaded", true);
      if (this.$store.getters.filters.q) {
        this.$nuxt.$on("informant:search", {
          q: this.$store.getters.filters.q,
        });
      }
      if (this.$cookies.get("token")) {
        this.$hyperUser.missingData();
      }
    }
    this.$nuxt.$on("openNewsletter", (v) => {
      this.openNewsletter = true;
      this.newsLetterType = v;
      this.$forceUpdate();
    });
    if (this.$route.name == "index" && process.browser) {
      this.$store.commit("expanded", true);
    }
  },
  methods: {
    checkForceReload() {
      try {
        let cacheVersion = localStorage.getItem("cacheVersion");
        if (
          this.$store.getters.store.cacheVersion &&
          cacheVersion != this.$store.getters.store.cacheVersion &&
          this.$route.name != "cart-step" &&
          !this.$route.fullPath.includes("cart")
        ) {
          localStorage.setItem(
            "cacheVersion",
            this.$store.getters.store.cacheVersion
          );
          location.reload(true);
        }
      } catch (e) {
        console.error(e);
      }
    },
    isMobileCalc() {
      if (process.browser) {
        // let width = (window.screen||{}).width||window.innerWidth;
        let width = window.innerWidth;
        if (this.$store.getters.isMobile && width < 920) {
          return;
        } else if (!this.$store.getters.isMobile && width < 920) {
          this.$store.commit("isMobile", true);
          return;
        }
        this.$store.commit("isMobile", false);
      }
      return false;
    },
    createCountdownTimer(t) {
      if (process.browser) {
        let now = Date.now();
        let remain = t.input - now;
        if (remain > 0) {
          let days = Math.floor(remain / (1000 * 60 * 60 * 24));
          let hours =
            Math.floor((remain % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) +
            "";
          let minutes =
            Math.floor((remain % (1000 * 60 * 60)) / (1000 * 60)) + "";
          let seconds = Math.floor((remain % (1000 * 60)) / 1000) + "";
          t.output = ``;
          if (days > 0) {
            t.output += `${days} `;
            t.output += `ημέρες `;
          }
          if (t.format != "short") {
            t.output += `${hours.padStart(2, 0)} ώρες ${minutes.padStart(
              2,
              0
            )} λεπτά ${seconds.padStart(2, 0)} δευτερόλεπτα`;
          } else {
            t.output += `${hours.padStart(2, 0)}:${minutes.padStart(
              2,
              0
            )}:${seconds.padStart(2, 0)}`;
          }
        } else {
          t.output = `00:00:00`;
        }
      }
      return t;
    },
    handleInjections() {
      if (process.browser) {
        if (
          this.$store.getters.store.eshop &&
          this.$store.getters.store.eshop.injections
        ) {
          this.$store.getters.store.eshop.injections
            .filter((i) =>
              !i.free && i.url == "*"
                ? true
                : i.url == "/"
                ? this.$route.fullPath == "/"
                : this.$route.fullPath.includes(i.url)
            )
            .forEach((i) => {
              if (!document.querySelector(`#shopster_injection_${i.index}`)) {
                setTimeout(() => {
                  try {
                    if (i.type == "script") {
                      let temp = i.script.split("@@@").filter((s) => s);
                      temp.forEach((s, sindex) => {
                        if (s.startsWith("http")) {
                          let e = document.createElement("script");
                          e.src = s;
                          e.id = `shopster_injection_${i.index}`;
                          document
                            .querySelector(
                              i.position == "head" ? "Head" : "#bodyScripts"
                            )
                            .appendChild(e);
                        } else {
                          let e = document.createElement("script");
                          e.innerHTML = s;
                          e.id = `shopster_injection_${i.index}`;
                          document
                            .querySelector(
                              i.position == "head" ? "Head" : "#bodyScripts"
                            )
                            .appendChild(e);
                        }
                      });
                    } else {
                      document.querySelector(
                        i.position == "head" ? "Head" : "#bodyScripts"
                      ).innerHTML += i.script;
                    }
                  } catch (error) {
                    console.error(error);
                    console.warn("safari cannot handle regex in 2022!");
                  }
                }, 10);
              }
            });
        }
      }
    },
    clearStorage() {
      localStorage.removeItem("state");
      localStorage.removeItem("cart");
      localStorage.removeItem("user");
      localStorage.removeItem("search");
      localStorage.removeItem("settings");
      localStorage.removeItem("store");
    },
  },
  computed: {},
};
